import { Label } from '@minimals/core';

interface StatusLabelProps {
  isActive: boolean;
}

// TODO i18n
export const StatusLabel = ({ isActive }: StatusLabelProps) => (
  <Label color={isActive ? 'primary' : 'default'}>{isActive ? 'AKTIV' : 'INAKTIV'}</Label>
);
