import { Backdrop, Drawer, Hidden, styled } from '@mui/material';

import { Scrollbar } from '@minimals/scrollbar';

type SidebarProps = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?(): void;
};

export const NAVIGATION_WIDTH = 250;

const Container = styled('div')({
  flexShrink: 0,
  width: NAVIGATION_WIDTH,
});

export const NavigationBar = ({ children, isOpen, onClose }: SidebarProps) => (
  <>
    <Hidden lgUp>
      <Backdrop
        open={isOpen}
        onClick={onClose}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      />
      <Drawer
        open={isOpen}
        onClose={onClose}
        variant="temporary"
        PaperProps={{
          sx: {
            overflowX: 'hidden',
            py: 3,
            width: NAVIGATION_WIDTH,
          },
        }}
      >
        <Scrollbar>{children}</Scrollbar>
      </Drawer>
    </Hidden>

    <Hidden lgDown>
      <Container>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              bgcolor: 'background.default',
              py: 3,
              width: NAVIGATION_WIDTH,
            },
          }}
        >
          <Scrollbar>{children}</Scrollbar>
        </Drawer>
      </Container>
    </Hidden>
  </>
);
