import Icon, { IconifyIcon } from '@iconify/react';
import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from '@mui/material';

interface MoreMenuItemProps extends MenuItemProps {
  icon: IconifyIcon['icon'];
  label: string;
}

export const MoreMenuItem = ({ icon, label, ...other }: MoreMenuItemProps) => {
  const { sx, ...rest } = other;
  return (
    <MenuItem sx={{ color: 'text.secondary', ...sx }} {...rest}>
      <ListItemIcon>
        <Icon icon={icon} width={24} height={24} />
      </ListItemIcon>
      <ListItemText primary={label} primaryTypographyProps={{ variant: 'body2' }} />
    </MenuItem>
  );
};
