import {
  Box,
  Button,
  Hidden,
  Table,
  TableBody,
  TableContainer,
  Typography,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { DelayedContent } from '@cubular/core';
import { ListCell, ListHead, ListRow, LoadingSpinner, SearchInput, useList } from '@minimals/core';

import { GetUsersListQuery, ListUserFragment } from '@/graphql';
import { HeadLabels, useBreakpoints, useListLabels } from '@/hooks';
import { PlusIcon } from '@/icons';
import { PATHS, createPath } from '@/routes';
import { listCellWidth } from '@/utils';

import { ListMoreMenu } from '../ListMoreMenu';
import { StatusLabel } from '../StatusLabel';

const HEAD_LABELS: HeadLabels = {
  upLg: [
    { label: 'Name', id: 'name' },
    { label: 'E-Mail Adresse', id: 'email' },
    { label: 'Position', id: 'job' },
    { label: 'Status', id: 'active' },
    { label: '', id: 'moremenu' },
  ],
  downLg: [
    { label: 'Name', id: 'name' },
    { label: 'Position', id: 'job' },
    { label: '', id: 'moremenu' },
  ],
  downMd: [
    { label: 'Name', id: 'name' },
    { label: '', id: 'moremenu' },
  ],
};

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

interface UsersListProps {
  isLoading?: boolean;
  items: GetUsersListQuery['getUsers'];
}

// TODO i18n
export const UsersList = ({ isLoading, items = [] }: UsersListProps) => {
  const navigate = useNavigate();
  const { downLgScreen } = useBreakpoints();
  const labels = useListLabels(HEAD_LABELS);

  const { filterName, filteredItems, handleFilterByName, handleRequestSort, order, orderBy } =
    useList(items);

  return (
    <>
      <HeaderContainer gap={2} mb={2}>
        <SearchInput
          onChange={(e) => handleFilterByName(e.target.value)}
          placeholder="Mitarbeiter suchen..."
          value={filterName}
        />
        <Button
          startIcon={<PlusIcon />}
          onClick={() => navigate(PATHS.users.new)}
          sx={{ px: 3 }}
          variant="contained"
        >
          Mitarbeiter hinzufügen
        </Button>
      </HeaderContainer>

      <TableContainer>
        <Table>
          <ListHead
            order={order}
            orderBy={orderBy}
            rowCount={filteredItems.length}
            headLabel={labels}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {!!filteredItems.length &&
              filteredItems.map((user: ListUserFragment) => {
                const editPath = createPath(PATHS.users.edit, user.id);
                const fullName =
                  user?.firstName && user?.lastName
                    ? user.firstName + ' ' + user.lastName
                    : user?.name;

                return (
                  <ListRow id={user.id} key={user.id} onClick={() => navigate(editPath)}>
                    <ListCell sx={listCellWidth(downLgScreen ? 0.5 : 0.23)}>{fullName}</ListCell>
                    <Hidden lgDown>
                      <ListCell sx={listCellWidth(0.17)}>{user.email}</ListCell>
                    </Hidden>
                    <Hidden mdDown>
                      <ListCell sx={listCellWidth(downLgScreen ? 0.3 : 0.17)}>{user?.job}</ListCell>
                    </Hidden>
                    <Hidden lgDown>
                      <ListCell sx={listCellWidth(0.1)}>
                        <StatusLabel isActive={user?.active} />
                      </ListCell>
                    </Hidden>
                    <ListMoreMenu editPath={editPath} />
                  </ListRow>
                );
              })}
          </TableBody>
        </Table>

        {isLoading && <LoadingSpinner sx={{ m: 3 }} />}

        {!isLoading && !filteredItems.length && (
          <DelayedContent LoadingComponent={LoadingSpinner}>
            <Typography sx={{ m: 3 }} variant="body1">
              Keine Mitarbeiter gefunden.
            </Typography>
          </DelayedContent>
        )}
      </TableContainer>
    </>
  );
};
