import searchFill from '@iconify/icons-eva/search-fill';
import Icon from '@iconify/react';
import { Box, InputAdornment, OutlinedInput, styled } from '@mui/material';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  height: 40,
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

interface SearchInputProps {
  onChange(e): void;
  placeholder: string;
  value: string;
}

export const SearchInput = ({ placeholder, onChange, value }: SearchInputProps) => (
  <SearchStyle
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    startAdornment={
      <InputAdornment position="start">
        <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
      </InputAdornment>
    }
  />
);
