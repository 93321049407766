import { useState } from 'react';

import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, TableCell, Typography, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { MoreMenu, MoreMenuItem } from '@minimals/core';

const Link = styled(RouterLink)({
  textDecoration: 'none',
});

interface ListMoreMenuProps {
  editPath: string;
  isDeleting?: boolean;
  onDelete?(): void;
}

// TODO i18n
export const ListMoreMenu = ({ editPath, isDeleting, onDelete }: ListMoreMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleDelete = () => {
    onDelete();
    handleClose();
  };

  return (
    <TableCell align="right" onClick={(e) => e.stopPropagation()}>
      <MoreMenu>
        <Link to={editPath}>
          <MoreMenuItem label="Bearbeiten" icon={editFill} />
        </Link>
        {!!onDelete && <MoreMenuItem label="Löschen" icon={trash2Outline} onClick={handleOpen} />}
      </MoreMenu>

      <Dialog open={isOpen} onClose={handleClose} PaperProps={{ sx: { p: 4 } }}>
        <Typography variant="h5" mb={1}>
          Sind Sie sicher, dass Sie dieses Element löschen möchten?
        </Typography>
        <Typography variant="body1" mb={2}>
          Dieser Vorgang lässt sich nicht rückgängig machen.
        </Typography>
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
          <Button color="inherit" onClick={handleClose}>
            Abbrechen
          </Button>
          <LoadingButton
            color="error"
            loading={isDeleting}
            onClick={handleDelete}
            variant="contained"
          >
            Löschen
          </LoadingButton>
        </Box>
      </Dialog>
    </TableCell>
  );
};
