import {
  Box,
  Button,
  Hidden,
  Table,
  TableBody,
  TableContainer,
  Typography,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { DelayedContent } from '@cubular/core';
import { stringsToList } from '@cubular/utils';
import { ListCell, ListHead, ListRow, LoadingSpinner, SearchInput, useList } from '@minimals/core';

import { GetLocationsListQuery, ListLocationFragment, useDeleteLocationMutation } from '@/graphql';
import { HeadLabels, useBreakpoints, useListLabels, useMutationSnackbar } from '@/hooks';
import { PlusIcon } from '@/icons';
import { PATHS, createPath } from '@/routes';
import { listCellWidth } from '@/utils';

import { ListMoreMenu } from '../ListMoreMenu';

const HEAD_LABELS: HeadLabels = {
  upLg: [
    { label: 'Standort Name', id: 'name' },
    { label: 'Adresse', id: 'locationInformation.street' },
    { label: '', id: 'moremenu' },
  ],
  downMd: [
    { label: 'Standort Name', id: 'name' },
    { label: '', id: 'moremenu' },
  ],
};

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

interface LocationsListProps {
  isLoading?: boolean;
  items: GetLocationsListQuery['getLocations'];
  refetch?(): void;
}

// TODO i18n
export const LocationsList = ({ isLoading, items = [], refetch }: LocationsListProps) => {
  const navigate = useNavigate();
  const { downMdScreen } = useBreakpoints();
  const labels = useListLabels(HEAD_LABELS);

  const onMutationResponse = useMutationSnackbar({
    successMessage: 'Der Standort wurden erfolgreich gelöscht.',
  });

  const [deleteLocation, { loading: isDeleting }] = useDeleteLocationMutation(onMutationResponse);

  const { handleFilterByName, handleRequestSort, filterName, order, orderBy, filteredItems } =
    useList(items);

  const handleDelete = async (id: string) => {
    await deleteLocation({ variables: { id } });
    refetch();
  };

  return (
    <>
      <HeaderContainer mb={2}>
        <SearchInput
          onChange={(e) => handleFilterByName(e.target.value)}
          placeholder="Standort suchen..."
          value={filterName}
        />
        <Button
          startIcon={<PlusIcon />}
          onClick={() => navigate(PATHS.locations.new)}
          sx={{ px: 3 }}
          variant="contained"
        >
          Standort hinzufügen
        </Button>
      </HeaderContainer>

      <TableContainer>
        <Table>
          <ListHead
            order={order}
            orderBy={orderBy}
            rowCount={filteredItems.length}
            headLabel={labels}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {!!filteredItems.length &&
              filteredItems.map((item: ListLocationFragment) => {
                const editPath = createPath(PATHS.locations.edit, item.id);
                return (
                  <ListRow id={item.id} key={item.id} onClick={() => navigate(editPath)}>
                    <ListCell sx={listCellWidth(downMdScreen ? 0.5 : 0.35)}>{item.name}</ListCell>
                    <Hidden mdDown>
                      <ListCell sx={listCellWidth(0.35)}>
                        {stringsToList([
                          item.locationInformation?.street,
                          item.locationInformation?.city,
                        ])}
                      </ListCell>
                    </Hidden>
                    <ListMoreMenu
                      editPath={editPath}
                      isDeleting={isDeleting}
                      onDelete={() => handleDelete(item.id)}
                    />
                  </ListRow>
                );
              })}
          </TableBody>
        </Table>

        {isLoading && <LoadingSpinner sx={{ m: 3 }} />}

        {!isLoading && !filteredItems.length && (
          <DelayedContent LoadingComponent={LoadingSpinner}>
            <Typography sx={{ m: 3 }} variant="body1">
              Keine Standorte gefunden.
            </Typography>
          </DelayedContent>
        )}
      </TableContainer>
    </>
  );
};
