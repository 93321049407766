import {
  Box,
  Checkbox,
  TableCell as MuiCell,
  TableHead,
  TableRow,
  TableSortLabel,
  styled,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

const TableCell = styled(MuiCell)({
  boxShadow: 'none !important',
});

type SelectProps =
  | {
      withSelect: true;
      onSelectAllClick: (checked: boolean) => void;
      numSelected: number;
    }
  | { withSelect?: false; onSelectAllClick?: (checked: boolean) => void; numSelected?: number };

interface HeadLabel {
  id: string;
  label: string;
}

type ListHeadProps = {
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headLabel: HeadLabel[];
  onRequestSort: (id: string) => void;
} & SelectProps;

export const ListHead = ({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  withSelect,
}: ListHeadProps) => (
  <TableHead>
    <TableRow>
      {withSelect && (
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onSelectAllClick(event.target.checked)
            }
          />
        </TableCell>
      )}
      {headLabel.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={'left'}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            hideSortIcon
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={() => onRequestSort(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box sx={{ ...visuallyHidden }}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
