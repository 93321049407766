import { ReactNode } from 'react';

import { styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(1),
  padding: `0 ${theme.spacing(2.5)}`,
}));

interface NavLogoProps {
  logo: ReactNode;
}

export const NavLogo = ({ logo }: NavLogoProps) => <Container>{logo}</Container>;
