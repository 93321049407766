import { forwardRef } from 'react';

import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps, useTheme } from '@mui/material';

type AvatarColor = 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';

export interface AvatarProps extends MuiAvatarProps {
  color?: AvatarColor;
}

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  ({ color = 'default', children, sx, ...other }, ref) => {
    const theme = useTheme();

    if (color === 'default') {
      return (
        <MuiAvatar ref={ref} sx={sx} {...other}>
          {children}
        </MuiAvatar>
      );
    }

    return (
      <MuiAvatar
        ref={ref}
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
          ...sx,
        }}
        {...other}
      >
        {children}
      </MuiAvatar>
    );
  }
);

Avatar.displayName = 'Avatar';
