import { Typography } from '@mui/material';
import { VariantType, useSnackbar } from 'notistack';

// You need to wrap your component/app with the SnackbarProvider to use this hook.
export const useTextSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const newSnackbar = (variant: VariantType, message: string) => {
    const key = enqueueSnackbar(
      <Typography
        color={variant === 'success' ? 'success.dark' : `${variant}.main`}
        variant="subtitle2"
      >
        {message}
      </Typography>,
      {
        variant,
        onClick: () => closeSnackbar(key),
      }
    );
  };

  return newSnackbar;
};
