import menuFill from '@iconify/icons-eva/menu-2-fill';
import Icon from '@iconify/react';
import { Hidden, IconButton, IconButtonProps } from '@mui/material';

interface NavToggleProps {
  onToggle(): void;
}

export const NavToggle = ({ onToggle, ...buttonProps }: NavToggleProps & IconButtonProps) => (
  <Hidden lgUp>
    <IconButton onClick={onToggle} {...buttonProps}>
      <Icon icon={menuFill} height={24} width={24} />
    </IconButton>
  </Hidden>
);
