import { StrictMode, useState } from 'react';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { BrowserRouter } from 'react-router-dom';

import { LoadingSpinner } from '@minimals/core';
import { ThemeProvider } from '@minimals/theme';
import { SnackbarProvider } from '@minimals/use-snackbar';

import keycloak from './Keycloak';
import { ApolloProviderComponent } from './graphql/ApolloProviderComponent';
import { AuthGuard } from './guards/AuthGuard';
import { AppLayout, FullScreenLayout } from './layouts';
import { Routes } from './routes';

const keycloakProviderInitConfig = {
  onLoad: 'login-required',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  sslRequired: 'external',
  resource: 'operator-app',
  publicClient: true,
  confidentialPort: 0,
};

export const App = () => {
  const [token, setToken] = useState('');

  return (
    <ThemeProvider>
      <ReactKeycloakProvider
        authClient={keycloak}
        isLoadingCheck={(keycloak) => !keycloak?.token}
        initOptions={keycloakProviderInitConfig}
        LoadingComponent={
          <FullScreenLayout>
            <LoadingSpinner />
          </FullScreenLayout>
        }
        onTokens={({ token }) => {
          setToken(token);
        }}
      >
        <StrictMode>
          <SnackbarProvider
            anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            disableWindowBlurListener
          >
            <AuthGuard>
              <ApolloProviderComponent token={token}>
                <BrowserRouter>
                  <AppLayout>
                    <Routes />
                  </AppLayout>
                </BrowserRouter>
              </ApolloProviderComponent>
            </AuthGuard>
          </SnackbarProvider>
        </StrictMode>
      </ReactKeycloakProvider>
    </ThemeProvider>
  );
};
