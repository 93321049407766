import { useEffect, useState } from 'react';

import { useBreakpoints } from './useBreakpoints';

type HeadLabel = {
  id: string;
  label: string;
};

export type HeadLabels = {
  downMd?: HeadLabel[];
  downLg?: HeadLabel[];
  upLg: HeadLabel[];
};

export const useListLabels = (headLabels: HeadLabels) => {
  const [labels, setLabels] = useState(headLabels.upLg);
  const { downMdScreen, downLgScreen } = useBreakpoints();

  useEffect(() => {
    if (downMdScreen) setLabels(headLabels?.downMd || headLabels?.downLg || headLabels.upLg);
    else if (downLgScreen) setLabels(headLabels?.downLg || headLabels.upLg);
    else setLabels(headLabels.upLg);
  }, [downLgScreen, downMdScreen, headLabels]);

  return labels;
};
