import { ReactNode, useEffect } from 'react';

import { useKeycloak } from '@react-keycloak/web';

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard = ({ children }: AuthGuardProps) => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(180);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => ({});
    };
  }, [initialized, keycloak]);

  return <>{children}</>;
};
