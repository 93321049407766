import filter from 'lodash/filter';
import get from 'lodash/get';

import { ListItemType } from '../List.types';

type Anonymous = Record<string | number, string>;

const descendingComparator = (a: Anonymous, b: Anonymous, orderBy: string) => {
  const elA = get(a, orderBy);
  const elB = get(b, orderBy);

  if (elB < elA) {
    return -1;
  }
  if (elB > elA) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: string, orderBy: string) =>
  order === 'desc'
    ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
    : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);

export const applySortFilter = (
  array: ListItemType[] = [],
  comparator: (a: any, b: any) => number
): ListItemType[] => {
  const stabilizedThis = array.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export const filterItemsByQuery = (items: ListItemType[] = [], query: string) => {
  if (query) {
    return filter(items, (_item) => {
      const item = _item?.['name'] || _item['email'];
      return item.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }

  return items;
};
