import { Box, CircularProgress, CircularProgressProps, styled } from '@mui/material';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

export const LoadingSpinner = (props: CircularProgressProps) => (
  <Container>
    <CircularProgress {...props} />
  </Container>
);
