import { forwardRef } from 'react';

import {
  Chip as MuiChip,
  ChipProps as MuiChipProps,
  alpha,
  emphasize,
  styled,
  useTheme,
} from '@mui/material';

import { ColorSchema } from '@minimals/theme';

interface ChipProps {
  styleProps?: {
    color: ColorSchema;
    variant: 'filled' | 'outlined';
    clickable?: boolean;
    onDelete?: (event: unknown) => void;
  };
}

const ChipStyle = styled(MuiChip)<ChipProps>(({ theme, styleProps }) => {
  const { color, variant, clickable, onDelete } = styleProps;

  // Filled
  const styleFilled = (color: ColorSchema) => ({
    color: theme.palette[color].contrastText,
    backgroundColor: theme.palette[color].main,
    '& .MuiChip-icon': { color: 'inherit' },
    '& .MuiChip-avatar': {
      color: theme.palette[color].lighter,
      backgroundColor: theme.palette[color].dark,
    },
    '& .MuiChip-deleteIcon': {
      color: alpha(theme.palette[color].contrastText, 0.7),
      '&:hover, &:active': { color: theme.palette[color].contrastText },
    },
  });

  const styleFilledClickable = (color: ColorSchema) => ({
    '&:hover, &:focus': {
      backgroundColor: emphasize(theme.palette[color].main, theme.palette.action.hoverOpacity),
    },
  });

  const styleFilledDeletable = (color: ColorSchema) => ({
    '&:focus': {
      backgroundColor: emphasize(theme.palette[color].main, theme.palette.action.focusOpacity),
    },
  });

  // Outlined
  const styleOutlined = (color: ColorSchema) => ({
    color: theme.palette[color].main,
    border: `1px solid ${theme.palette[color].main}`,
    '&:focus, &.MuiChip-clickable:hover': {
      backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
    },
    '& .MuiChip-icon': { color: 'currentColor' },
    '& .MuiChip-avatar': {
      color: theme.palette[color].lighter,
      backgroundColor: theme.palette[color].dark,
    },
    '& .MuiChip-deleteIcon': {
      color: alpha(theme.palette[color].main, 0.7),
      '&:hover, &:active': { color: theme.palette[color].main },
    },
  });

  return {
    ...(variant === 'filled'
      ? {
          ...styleFilled(color),
          ...(clickable && { ...styleFilledClickable(color) }),
          ...(onDelete && { ...styleFilledDeletable(color) }),
        }
      : {
          ...styleOutlined(color),
        }),
  };
});

interface ChipProps extends Omit<MuiChipProps, 'color'> {
  color?: 'default' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error';
}

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      color = 'default',
      variant = 'filled',
      clickable: clickableProp,
      onDelete: onDeleteProp,
      ...other
    },
    ref
  ) => {
    const theme = useTheme();

    if (color === 'default' || color === 'primary' || color === 'secondary') {
      return (
        <MuiChip
          ref={ref}
          color={color}
          variant={variant}
          clickable={clickableProp && clickableProp}
          onDelete={onDeleteProp && onDeleteProp}
          {...other}
        />
      );
    }

    return (
      <ChipStyle
        ref={ref}
        variant={variant}
        clickable={clickableProp && clickableProp}
        onDelete={onDeleteProp && onDeleteProp}
        styleProps={{
          color,
          variant,
          clickable: clickableProp && clickableProp,
          onDelete: onDeleteProp && onDeleteProp,
        }}
        theme={theme}
        {...other}
      />
    );
  }
);

Chip.displayName = 'Chip';
