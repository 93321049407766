import { Suspense, lazy } from 'react';

import { CircularProgress } from '@mui/material';
import { Navigate, Route, Routes as RoutesWrapper } from 'react-router-dom';

import { PATHS, createPath } from './paths';

export const Routes = () => (
  <RoutesWrapper>
    <Route path="/" element={<Navigate to={PATHS.organization.root} />} />
    <Route path={PATHS.organization.root} element={<OrganizationPage />} />

    <Route path={PATHS.locations.root} element={<LocationsPage />} />
    <Route path={PATHS.locations.new} element={<LocationNewPage />} />
    <Route path={createPath(PATHS.locations.edit, ':id')} element={<LocationEditPage />} />

    <Route path={PATHS.users.root} element={<UsersPage />} />
    <Route path={PATHS.users.new} element={<UserNewPage />} />
    <Route path={createPath(PATHS.users.edit, ':id')} element={<UserEditPage />} />

    <Route path={PATHS.devices.root} element={<DevicesPage />} />
    <Route path={createPath(PATHS.devices.edit, ':id')} element={<DeviceEditPage />} />

    <Route path={PATHS[404]} element={<NotFoundPage />} />
    <Route path="*" element={<Navigate to="/404" replace />} />
  </RoutesWrapper>
);

// eslint-disable-next-line react/display-name
const Loadable = (Component: any) => (props: any) => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Component {...props} />
    </Suspense>
  );
};

// Imports

const OrganizationPage = Loadable(lazy(() => import('../pages/organization/OrganizationPage')));

const LocationsPage = Loadable(lazy(() => import('../pages/locations/LocationsPage')));
const LocationEditPage = Loadable(lazy(() => import('../pages/locations/LocationEditPage')));
const LocationNewPage = Loadable(lazy(() => import('../pages/locations/LocationNewPage')));

const UsersPage = Loadable(lazy(() => import('../pages/users/UsersPage')));
const UserEditPage = Loadable(lazy(() => import('../pages/users/UserEditPage')));
const UserNewPage = Loadable(lazy(() => import('../pages/users/UserNewPage')));

const DevicesPage = Loadable(lazy(() => import('../pages/devices/DevicesPage')));
const DeviceEditPage = Loadable(lazy(() => import('../pages/devices/DeviceEditPage')));

const NotFoundPage = Loadable(lazy(() => import('../pages/NotFoundPage')));
