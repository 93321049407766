import { ReactNode } from 'react';

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

interface ApolloProviderProps {
  children: ReactNode;
  token: string;
}

const createApolloClient = (token: string) => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));

  const httpLink = new HttpLink({
    uri: import.meta.env.VITE_GREEETS_GQL_QUERY_URL,
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
};

export const ApolloProviderComponent = ({ children, token }: ApolloProviderProps) => {
  const client = createApolloClient(token);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
