interface Shape {
  borderRadius: number | string;
  borderRadiusSm: number | string;
  borderRadiusMd: number | string;
}

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    shape: Shape;
  }
}

export const shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
};
