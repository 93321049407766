import { ReactNode, SyntheticEvent } from 'react';

import { Checkbox, TableCell, TableRow, TableRowProps } from '@mui/material';

import { UseList } from './List.types';

type SelectProps =
  | {
      withSelect: true;
      onSelect: UseList['handleSelect'];
      selected: UseList['selected'];
    }
  | { withSelect?: false; onSelect?: UseList['handleSelect']; selected?: UseList['selected'] };

type ListRowProps = {
  children: ReactNode;
  id: string;
  onClick?(): void;
} & SelectProps &
  Omit<TableRowProps, 'selected'>;

export const ListRow = ({
  children,
  onSelect,
  id,
  onClick,
  selected,
  withSelect,
  ...props
}: ListRowProps) => {
  const { sx, ...rest } = props;
  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const rowProps = {
    onClick: handleClick,
    sx: { cursor: onClick ? 'pointer' : 'auto', ...sx },
    ...rest,
  };

  if (withSelect) {
    const isItemSelected = selected?.indexOf(id) !== -1;

    return (
      <TableRow
        hover
        key={id}
        tabIndex={-1}
        role="checkbox"
        selected={isItemSelected}
        aria-checked={isItemSelected}
        {...rowProps}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            onClick={(e) => {
              e.stopPropagation();
              onSelect(id);
            }}
          />
        </TableCell>
        {children}
      </TableRow>
    );
  }

  return (
    <TableRow key={id} hover tabIndex={-1} {...rowProps}>
      {children}
    </TableRow>
  );
};
