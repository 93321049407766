import { FabProps, useTheme } from '@mui/material';

import { Fab } from './Fab';

export const BackButton = (props: FabProps) => {
  const theme = useTheme();
  return (
    <Fab size="small" {...props}>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.5252 25.8333C21.2731 25.8342 21.0341 25.7208 20.8752 25.525L16.8502 20.525C16.5975 20.2175 16.5975 19.7742 16.8502 19.4667L21.0169 14.4667C21.3114 14.1123 21.8375 14.0638 22.1919 14.3583C22.5463 14.6529 22.5948 15.1789 22.3002 15.5333L18.5752 20L22.1752 24.4667C22.3833 24.7164 22.4271 25.0644 22.2875 25.358C22.1479 25.6515 21.8503 25.8371 21.5252 25.8333Z"
          fill={theme.palette.primary.main}
        />
      </svg>
    </Fab>
  );
};
