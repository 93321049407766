import { Box, BoxProps, styled } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

export const CardsLayout = ({ children, ...containerProps }: BoxProps) => (
  <Container
    alignItems={{ xs: 'stretch', md: 'flex-start' }}
    flexDirection={{ xs: 'column', md: 'row' }}
    {...containerProps}
  >
    {children}
  </Container>
);
