export const createPath = (root: string, sublink: string) => `${root}/${sublink}`;

export const ROOTS = {
  organization: '/organization',
  devices: '/devices',
  users: '/users',
  locations: '/locations',
};

export const PATHS = {
  organization: {
    root: ROOTS.organization,
  },
  devices: {
    root: ROOTS.devices,
    edit: createPath(ROOTS.devices, 'edit'),
    new: createPath(ROOTS.devices, 'new'),
  },
  users: {
    root: ROOTS.users,
    edit: createPath(ROOTS.users, 'edit'),
    new: createPath(ROOTS.users, 'new'),
  },
  locations: {
    root: ROOTS.locations,
    edit: createPath(ROOTS.locations, 'edit'),
    new: createPath(ROOTS.locations, 'new'),
  },
  404: '/404',
};
