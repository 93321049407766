import { ReactNode } from 'react';

import { Box, SxProps, styled } from '@mui/material';

const Container = styled('div')({
  height: '100%',
  width: '100%',
  minHeight: '100%',
  display: 'flex',
  maxWidth: '100%',
});

const ContentContainer = styled(Box)({
  width: '100%',
});

const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  maxWidth: '1900px',
  padding: theme.spacing(2),
}));

interface MainLayoutProps {
  children: ReactNode;
  header?: ReactNode;
  navigation?: ReactNode;
  contentContainerSx?: SxProps;
}

export const MainLayout = ({
  children,
  contentContainerSx,
  header,
  navigation,
}: MainLayoutProps) => (
  <Container>
    {navigation}
    <ContentContainer sx={contentContainerSx}>
      {header}
      <Content>{children}</Content>
    </ContentContainer>
  </Container>
);
