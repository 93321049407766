import { Box, SxProps, Typography, styled } from '@mui/material';
import capitalize from 'lodash/capitalize';

import { Avatar } from './Avatar';

const AccountStyle = styled(Box)(({ theme }) => ({
  overflowX: 'hidden',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(2, 2.5, 2, 3.5),
}));

interface UserInfoProps {
  displayName: string;
  meta?: string;
  avatar?: string;
  sx?: SxProps;
}

export const UserInfo = ({ displayName = '', meta, avatar, sx }: UserInfoProps) => (
  <AccountStyle sx={{ mb: 2, ...sx }}>
    <Avatar src={avatar}>{capitalize(displayName.charAt(0))}</Avatar>
    <Box sx={{ overflow: 'hidden', ml: 2 }}>
      <Typography
        variant="subtitle2"
        sx={{ color: 'text.primary', textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {displayName}
      </Typography>
      {meta && (
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary', textOverflow: 'ellipsis', overflow: 'hidden' }}
        >
          {meta}
        </Typography>
      )}
    </Box>
  </AccountStyle>
);
