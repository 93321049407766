import { ReactNode, useState } from 'react';

import { Hidden, useMediaQuery, useTheme } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router-dom';

import { ErrorBoundary } from '@cubular/core';
import { BackButton, Fab, Header, HeaderTitle, MainLayout } from '@minimals/core';
import { LeaveIcon } from '@minimals/icons';
import { NavToggle } from '@minimals/navigation-bar';

import { ErrorBoundaryMessage, Navigation } from '@/components';
import { lgContentWidth } from '@/utils';
import { useZustand } from '@/zustand';

interface AppLayoutProps {
  children: ReactNode;
}

export const AppLayout = ({ children }: AppLayoutProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { pageTitle } = useZustand((state) => state);
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => setIsOpen(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const navigateBackwards = () => navigate(-1);

  const handleLogout = () => {
    keycloak.logout();
  };

  return (
    <ErrorBoundary errorMessage={<ErrorBoundaryMessage />}>
      <MainLayout
        contentContainerSx={{ maxWidth: { lg: lgContentWidth } }}
        navigation={<Navigation isOpen={isOpen} handleClose={handleClose} />}
        header={
          <Header
            left={
              <>
                <NavToggle onToggle={handleToggle} sx={{ mr: 1 }} />
                <Hidden lgDown>
                  <BackButton onClick={navigateBackwards} sx={{ mr: 1 }} />
                </Hidden>
                <HeaderTitle>{pageTitle}</HeaderTitle>
              </>
            }
            right={
              <Fab onClick={handleLogout} size={isXsScreen ? 'small' : 'medium'}>
                <LeaveIcon />
              </Fab>
            }
          />
        }
      >
        {children}
      </MainLayout>
    </ErrorBoundary>
  );
};
