import { Typography as MuiTypography, styled } from '@mui/material';

const Typography = styled(MuiTypography)({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  maxWidth: '50vw',
});

interface HeaderTitleProps {
  children: string;
  subtitle?: string;
}

export const HeaderTitle = ({ children, subtitle }: HeaderTitleProps) => (
  <>
    <Typography variant="h6">{children}</Typography>
    <Typography variant="body1">{subtitle}</Typography>
  </>
);
