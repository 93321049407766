import { ReactNode } from 'react';

import { styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.action.disabledBackground}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '60px',
  padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  width: '100%',
  position: 'sticky',
  zIndex: 100,
  top: 0,
  background: theme.palette.background.default,
}));

const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

interface HeaderProps {
  left?: ReactNode;
  right?: ReactNode;
}

export const Header = ({ left, right }: HeaderProps) => (
  <Container>
    <Wrapper>{left}</Wrapper>
    <Wrapper>{right}</Wrapper>
  </Container>
);
