import { ReactNode } from 'react';

import roundAddAPhoto from '@iconify/icons-ic/round-add-a-photo';
import { Icon } from '@iconify/react';
import { Box, Button, SxProps, Theme, Typography, styled } from '@mui/material';
import { DropzoneOptions, useDropzone } from 'react-dropzone';

import { Image } from './Image';
import { RejectionFiles } from './RejectionFiles';

const RootStyle = styled('div')(({ theme }) => ({
  width: 220,
  height: 220,
  margin: 'auto',
  borderRadius: '50%',
  padding: theme.spacing(1),
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  marginBottom: theme.spacing(2),
}));

const DropZoneStyle = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  borderRadius: '50%',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.neutral,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&:hover': { opacity: 0.72 },
}));

export interface CustomFile extends File {
  path?: string;
  preview?: string;
  lastModifiedDate?: Date;
}

interface UploadProps extends DropzoneOptions {
  captions?: string[];
  children?: ReactNode;
  error?: boolean;
  file: CustomFile | string | null;
  onDelete?(): void;
  sx?: SxProps<Theme>;
}

// TODO i18n
export const UploadAvatar = ({
  captions,
  children,
  error,
  file,
  onDelete,
  sx,
  ...other
}: UploadProps) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  return (
    <>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />

          {file && (
            <Image
              alt="avatar"
              src={typeof file === 'string' ? file : URL.createObjectURL(file)}
              sx={{ zIndex: 8 }}
            />
          )}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(file && {
                opacity: 0,
                color: 'common.white',
                bgcolor: 'grey.900',
                '&:hover': { opacity: 0.72 },
              }),
              ...((isDragReject || error) && {
                bgcolor: 'error.lighter',
              }),
            }}
          >
            <Box component={Icon} icon={roundAddAPhoto} sx={{ width: 24, height: 24, mb: 1 }} />
            <Typography variant="caption">{file ? 'Foto updaten' : 'Foto hochladen'}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      {file && onDelete && (
        <Button
          color="error"
          onClick={onDelete}
          size="small"
          sx={{ position: 'absolute', top: '2%', right: '2%' }}
          variant="outlined"
        >
          entfernen
        </Button>
      )}

      {!!captions?.length &&
        captions.map((caption, index) => (
          <Typography align="center" key={index} mb={0} paragraph variant="caption">
            {caption}
          </Typography>
        ))}

      {children ? <Box sx={{ textAlign: 'center' }}>{children}</Box> : null}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  );
};
