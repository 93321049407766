import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { useOrderTerminalMutation } from '@/graphql';
import { useMutationSnackbar } from '@/hooks';

// TODO i18n
export const DeviceOrderDialog = ({ open, handleClose }) => {
  const onMutationResponse = useMutationSnackbar({
    successMessage: 'Ein neues Gerät wurde bestellt.',
  });
  const [orderTerminal, { loading }] = useOrderTerminalMutation(onMutationResponse);

  const handleOrder = () => {
    orderTerminal();
    handleClose();
  };
  return (
    <Dialog open={open} keepMounted onClose={handleClose}>
      <DialogTitle>Sind Sie sicher, dass sie ein neues Gerät bestellen möchten?</DialogTitle>
      <DialogContent>
        <DialogContentText>Falls ja, klicken Sie auf Bestellen.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>
          Abbrechen
        </Button>
        <LoadingButton loading={loading} variant="contained" onClick={handleOrder}>
          Bestellen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
