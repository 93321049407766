import create from 'zustand';
import { combine, devtools, persist } from 'zustand/middleware';

export const useZustand = create(
  devtools(
    persist(
      combine({ pageTitle: '' }, (set) => ({
        setPageTitle: (pageTitle: string) => set(() => ({ pageTitle })),
      }))
    )
  )
);
