import { Card, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// TODO i18n
export const ErrorBoundaryMessage = () => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        p: 4,
        m: 4,
      }}
    >
      <Typography variant="h4">Es ist etwas schief gelaufen.</Typography>
      <Link onClick={() => navigate(0)}>
        <Typography variant="body1">Bitte laden Sie die Seite erneut.</Typography>
      </Link>
    </Card>
  );
};
