import { FabProps, Fab as MuiFab, styled } from '@mui/material';

const transitionDuration = '0.6s';

const StyledFab = styled(MuiFab, {
  shouldForwardProp: (prop) => prop !== 'hoverColor',
})<{ hoverColor?: string }>(({ hoverColor }) => ({
  transitionDuration,
  '&:hover': {
    ...(hoverColor && { backgroundColor: hoverColor }),
    'svg > *': {
      fill: 'white',
      transitionDuration,
    },
  },
}));

export const Fab = (props: FabProps & { hoverColor?: string }) => {
  const { children, hoverColor, sx, ...rest } = props;
  return (
    <StyledFab {...rest} hoverColor={hoverColor} sx={{ bgcolor: 'grey.100', ...sx }}>
      {children}
    </StyledFab>
  );
};
