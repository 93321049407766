import { ElementType, ReactNode, useEffect, useState } from 'react';

export interface DelayedContentProps {
  children: ReactNode;
  LoadingComponent: ElementType;
  delay?: number;
}

export const DelayedContent = ({
  children,
  delay = 100,
  LoadingComponent,
}: DelayedContentProps): JSX.Element => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return show ? <>{children}</> : <LoadingComponent />;
};
