import { useEffect, useState } from 'react';

import { ListItemType, UseList } from './List.types';
import { applySortFilter, filterItemsByQuery, getComparator } from './utils/sorting';

export const useList = (listItems: ListItemType[] = []): UseList => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [filteredItems, setFilteredItems] = useState<ListItemType[]>([]);

  useEffect(() => {
    if (listItems) {
      const newFiltered = filterItemsByQuery(listItems, filterName);
      const newItems = applySortFilter(newFiltered, getComparator(order, orderBy));
      setFilteredItems(newItems);
    }
  }, [filterName, listItems, order, orderBy]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked: boolean) => {
    if (checked) {
      const newSelecteds = listItems.map((n) => n['id']);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelect = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (filterName: string) => {
    setFilterName(filterName);
  };

  return {
    filterName,
    handleSelect,
    handleFilterByName,
    handleRequestSort,
    handleSelectAllClick,
    order,
    orderBy,
    filteredItems,
    selected,
    setFilterName,
    setFilteredItems,
  };
};
