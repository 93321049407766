import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Time: any;
  Timestamp: any;
};

export type ActiveInformation = {
  __typename?: 'ActiveInformation';
  activeOperators: Scalars['Int'];
  activeTerminals: Scalars['Int'];
};

export type CallRequest = {
  __typename?: 'CallRequest';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationInformation?: Maybe<ContactInformation>;
  locationName?: Maybe<Scalars['String']>;
  status: CallStatus;
  terminalName?: Maybe<Scalars['String']>;
  timeout?: Maybe<Scalars['Timestamp']>;
};

export enum CallResult {
  Aborted = 'ABORTED',
  Leave = 'LEAVE',
  ToWaitingroom = 'TO_WAITINGROOM',
}

export type CallResultInput = {
  result: CallResult;
  waittime?: InputMaybe<Scalars['Int']>;
};

export enum CallStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type ContactInformation = {
  __typename?: 'ContactInformation';
  city?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  taxId?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type ContactInformationInput = {
  city?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  taxId?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  closingTime?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locationInformation?: Maybe<ContactInformation>;
  name: Scalars['String'];
  openingTime?: Maybe<Scalars['String']>;
  operators?: Maybe<Array<Maybe<User>>>;
};

export type LocationInput = {
  closingTime?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  locationInformation?: InputMaybe<ContactInformationInput>;
  name?: InputMaybe<Scalars['String']>;
  openingTime?: InputMaybe<Scalars['String']>;
  operators?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCall?: Maybe<Scalars['String']>;
  createLocation?: Maybe<Location>;
  createOrUpdateContactInformation?: Maybe<ContactInformation>;
  createOrganization?: Maybe<Organization>;
  createUser?: Maybe<User>;
  deleteLocation?: Maybe<Scalars['String']>;
  deleteOrganization?: Maybe<Scalars['String']>;
  finishCall?: Maybe<Scalars['String']>;
  orderTerminal?: Maybe<Scalars['String']>;
  pauseCall?: Maybe<Scalars['String']>;
  resumeCall?: Maybe<Scalars['String']>;
  updateLocation?: Maybe<Location>;
  updateOrganization?: Maybe<Organization>;
  updateTerminal?: Maybe<Terminal>;
  updateUser?: Maybe<User>;
};

export type MutationAcceptCallArgs = {
  callRequest: Scalars['ID'];
};

export type MutationCreateLocationArgs = {
  input?: InputMaybe<LocationInput>;
};

export type MutationCreateOrUpdateContactInformationArgs = {
  input?: InputMaybe<ContactInformationInput>;
};

export type MutationCreateOrganizationArgs = {
  input?: InputMaybe<OrganizationInput>;
};

export type MutationCreateUserArgs = {
  input?: InputMaybe<UserInput>;
};

export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};

export type MutationFinishCallArgs = {
  id: Scalars['ID'];
  result: CallResultInput;
};

export type MutationPauseCallArgs = {
  id: Scalars['ID'];
};

export type MutationResumeCallArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateLocationArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<LocationInput>;
};

export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<OrganizationInput>;
};

export type MutationUpdateTerminalArgs = {
  input?: InputMaybe<TerminalInput>;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UserInput>;
};

export type Organization = {
  __typename?: 'Organization';
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationInformation?: Maybe<ContactInformation>;
};

export type OrganizationInput = {
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  newFileName?: InputMaybe<Scalars['String']>;
  organizationInformation?: InputMaybe<ContactInformationInput>;
};

export type Query = {
  __typename?: 'Query';
  getLocation?: Maybe<Location>;
  getLocations?: Maybe<Array<Maybe<Location>>>;
  getOrganization?: Maybe<Organization>;
  getQueue?: Maybe<Array<Maybe<CallRequest>>>;
  getRoomToken?: Maybe<RoomToken>;
  getTerminal?: Maybe<Terminal>;
  getTerminalsByLocations?: Maybe<Array<Maybe<Terminal>>>;
  getTerminalsByOrganization?: Maybe<Array<Maybe<Terminal>>>;
  getUser?: Maybe<User>;
  getUsers?: Maybe<Array<Maybe<User>>>;
  me?: Maybe<User>;
};

export type QueryGetLocationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetTerminalArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetTerminalsByLocationsArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueueMessage = {
  __typename?: 'QueueMessage';
  callRequest: CallRequest;
  type: Scalars['String'];
};

export enum QueueMessageType {
  New = 'NEW',
  Resolved = 'RESOLVED',
}

export type RoomToken = {
  __typename?: 'RoomToken';
  expiresAt?: Maybe<Scalars['Timestamp']>;
  room?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activeCall?: Maybe<Scalars['String']>;
  queue?: Maybe<Array<Maybe<CallRequest>>>;
};

export type Terminal = {
  __typename?: 'Terminal';
  description?: Maybe<Scalars['String']>;
  error?: Maybe<TerminalError>;
  id: Scalars['ID'];
  information?: Maybe<TerminalInformation>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type TerminalError = {
  __typename?: 'TerminalError';
  code: Scalars['String'];
  description: Scalars['String'];
};

export type TerminalInformation = {
  __typename?: 'TerminalInformation';
  createdAt?: Maybe<Scalars['Timestamp']>;
  type?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type TerminalInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  active?: Maybe<Scalars['Boolean']>;
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  job?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  locations?: Maybe<Array<Maybe<Location>>>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  role?: Maybe<UserRole>;
};

export type UserInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  job?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  newFileName?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  LocationAdmin = 'LOCATION_ADMIN',
  Operator = 'OPERATOR',
}

export type GetTerminalLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetTerminalLocationsQuery = {
  __typename?: 'Query';
  getLocations?: Array<{ __typename?: 'Location'; id: string; name: string } | null> | null;
};

export type OrderTerminalMutationVariables = Exact<{ [key: string]: never }>;

export type OrderTerminalMutation = { __typename?: 'Mutation'; orderTerminal?: string | null };

export type TerminalFragment = {
  __typename?: 'Terminal';
  id: string;
  name?: string | null;
  description?: string | null;
  status?: string | null;
  location?: {
    __typename?: 'Location';
    id: string;
    name: string;
    description?: string | null;
    email?: string | null;
    openingTime?: string | null;
    closingTime?: string | null;
    locationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      comment?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      taxId?: string | null;
      zip?: string | null;
    } | null;
  } | null;
  information?: {
    __typename?: 'TerminalInformation';
    type?: string | null;
    version?: string | null;
    createdAt?: any | null;
  } | null;
};

export type UpdateTerminalMutationVariables = Exact<{
  input?: InputMaybe<TerminalInput>;
}>;

export type UpdateTerminalMutation = {
  __typename?: 'Mutation';
  updateTerminal?: {
    __typename?: 'Terminal';
    id: string;
    name?: string | null;
    description?: string | null;
    status?: string | null;
    location?: {
      __typename?: 'Location';
      id: string;
      name: string;
      description?: string | null;
      email?: string | null;
      openingTime?: string | null;
      closingTime?: string | null;
      locationInformation?: {
        __typename?: 'ContactInformation';
        city?: string | null;
        comment?: string | null;
        country?: string | null;
        phone?: string | null;
        state?: string | null;
        street?: string | null;
        taxId?: string | null;
        zip?: string | null;
      } | null;
    } | null;
    information?: {
      __typename?: 'TerminalInformation';
      type?: string | null;
      version?: string | null;
      createdAt?: any | null;
    } | null;
  } | null;
};

export type CreateLocationMutationVariables = Exact<{
  input?: InputMaybe<LocationInput>;
}>;

export type CreateLocationMutation = {
  __typename?: 'Mutation';
  createLocation?: {
    __typename?: 'Location';
    id: string;
    name: string;
    description?: string | null;
    email?: string | null;
    openingTime?: string | null;
    closingTime?: string | null;
    locationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      comment?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      taxId?: string | null;
      zip?: string | null;
    } | null;
  } | null;
};

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteLocationMutation = { __typename?: 'Mutation'; deleteLocation?: string | null };

export type GetAutocompleteUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAutocompleteUsersQuery = {
  __typename?: 'Query';
  getUsers?: Array<{
    __typename?: 'User';
    avatar?: string | null;
    email: string;
    firstName?: string | null;
    id: string;
    lastName?: string | null;
    role?: UserRole | null;
  } | null> | null;
};

export type GetLocationsListQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocationsListQuery = {
  __typename?: 'Query';
  getLocations?: Array<{
    __typename?: 'Location';
    id: string;
    name: string;
    locationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      street?: string | null;
    } | null;
  } | null> | null;
};

export type GetTerminalsOverviewQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetTerminalsOverviewQuery = {
  __typename?: 'Query';
  getTerminalsByLocations?: Array<{
    __typename?: 'Terminal';
    id: string;
    description?: string | null;
    status?: string | null;
    name?: string | null;
  } | null> | null;
};

export type ListLocationFragment = {
  __typename?: 'Location';
  id: string;
  name: string;
  locationInformation?: {
    __typename?: 'ContactInformation';
    city?: string | null;
    street?: string | null;
  } | null;
};

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<LocationInput>;
}>;

export type UpdateLocationMutation = {
  __typename?: 'Mutation';
  updateLocation?: {
    __typename?: 'Location';
    id: string;
    name: string;
    description?: string | null;
    email?: string | null;
    openingTime?: string | null;
    closingTime?: string | null;
    locationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      comment?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      taxId?: string | null;
      zip?: string | null;
    } | null;
  } | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me?: {
    __typename?: 'User';
    job?: string | null;
    name?: string | null;
    avatar?: string | null;
  } | null;
};

export type CreateUserMutationVariables = Exact<{
  input?: InputMaybe<UserInput>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: { __typename?: 'User'; id: string } | null;
};

export type GetAutocompleteLocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAutocompleteLocationsQuery = {
  __typename?: 'Query';
  getLocations?: Array<{ __typename?: 'Location'; id: string; name: string } | null> | null;
};

export type ListUserFragment = {
  __typename?: 'User';
  id: string;
  email: string;
  active?: boolean | null;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  job?: string | null;
};

export type GetUsersListQueryVariables = Exact<{ [key: string]: never }>;

export type GetUsersListQuery = {
  __typename?: 'Query';
  getUsers?: Array<{
    __typename?: 'User';
    id: string;
    email: string;
    active?: boolean | null;
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    job?: string | null;
  } | null> | null;
};

export type UpdateUserMutationVariables = Exact<{
  input?: InputMaybe<UserInput>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: { __typename?: 'User'; id: string } | null;
};

export type ContactInformationFragment = {
  __typename?: 'ContactInformation';
  city?: string | null;
  comment?: string | null;
  country?: string | null;
  phone?: string | null;
  state?: string | null;
  street?: string | null;
  taxId?: string | null;
  zip?: string | null;
};

export type LocationFragment = {
  __typename?: 'Location';
  id: string;
  name: string;
  description?: string | null;
  email?: string | null;
  openingTime?: string | null;
  closingTime?: string | null;
  locationInformation?: {
    __typename?: 'ContactInformation';
    city?: string | null;
    comment?: string | null;
    country?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    taxId?: string | null;
    zip?: string | null;
  } | null;
};

export type OperatorsAutocompleteFragment = {
  __typename?: 'User';
  avatar?: string | null;
  email: string;
  firstName?: string | null;
  id: string;
  lastName?: string | null;
  role?: UserRole | null;
};

export type GetTerminalQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetTerminalQuery = {
  __typename?: 'Query';
  getTerminal?: {
    __typename?: 'Terminal';
    id: string;
    description?: string | null;
    name?: string | null;
    error?: { __typename?: 'TerminalError'; description: string } | null;
    location?: {
      __typename?: 'Location';
      id: string;
      name: string;
      locationInformation?: {
        __typename?: 'ContactInformation';
        city?: string | null;
        street?: string | null;
      } | null;
    } | null;
  } | null;
};

export type GetTerminalsListQueryVariables = Exact<{ [key: string]: never }>;

export type GetTerminalsListQuery = {
  __typename?: 'Query';
  getTerminalsByOrganization?: Array<{
    __typename?: 'Terminal';
    id: string;
    name?: string | null;
    description?: string | null;
    status?: string | null;
    location?: {
      __typename?: 'Location';
      locationInformation?: {
        __typename?: 'ContactInformation';
        city?: string | null;
        street?: string | null;
      } | null;
    } | null;
  } | null> | null;
};

export type ListTerminalFragment = {
  __typename?: 'Terminal';
  id: string;
  name?: string | null;
  description?: string | null;
  status?: string | null;
  location?: {
    __typename?: 'Location';
    locationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      street?: string | null;
    } | null;
  } | null;
};

export type GetLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetLocationQuery = {
  __typename?: 'Query';
  getLocation?: {
    __typename?: 'Location';
    id: string;
    name: string;
    description?: string | null;
    email?: string | null;
    openingTime?: string | null;
    closingTime?: string | null;
    operators?: Array<{
      __typename?: 'User';
      avatar?: string | null;
      email: string;
      firstName?: string | null;
      id: string;
      lastName?: string | null;
      role?: UserRole | null;
    } | null> | null;
    locationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      comment?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      taxId?: string | null;
      zip?: string | null;
    } | null;
  } | null;
};

export type CreateOrganizationMutationVariables = Exact<{
  input?: InputMaybe<OrganizationInput>;
}>;

export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    avatar?: string | null;
    email: string;
    description?: string | null;
    organizationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      comment?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      taxId?: string | null;
      zip?: string | null;
    } | null;
  } | null;
};

export type GetOrganizationQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganizationQuery = {
  __typename?: 'Query';
  getOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    avatar?: string | null;
    email: string;
    description?: string | null;
    organizationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      comment?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      taxId?: string | null;
      zip?: string | null;
    } | null;
  } | null;
};

export type OrganizationFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  avatar?: string | null;
  email: string;
  description?: string | null;
  organizationInformation?: {
    __typename?: 'ContactInformation';
    city?: string | null;
    comment?: string | null;
    country?: string | null;
    phone?: string | null;
    state?: string | null;
    street?: string | null;
    taxId?: string | null;
    zip?: string | null;
  } | null;
};

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<OrganizationInput>;
}>;

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    avatar?: string | null;
    email: string;
    description?: string | null;
    organizationInformation?: {
      __typename?: 'ContactInformation';
      city?: string | null;
      comment?: string | null;
      country?: string | null;
      phone?: string | null;
      state?: string | null;
      street?: string | null;
      taxId?: string | null;
      zip?: string | null;
    } | null;
  } | null;
};

export type GetUserQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser?: {
    __typename?: 'User';
    id: string;
    avatar?: string | null;
    description?: string | null;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    active?: boolean | null;
    role?: UserRole | null;
    job?: string | null;
    locations?: Array<{ __typename?: 'Location'; id: string; name: string } | null> | null;
  } | null;
};

export type UserFormFragment = {
  __typename?: 'User';
  id: string;
  avatar?: string | null;
  description?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  active?: boolean | null;
  role?: UserRole | null;
  job?: string | null;
  locations?: Array<{ __typename?: 'Location'; id: string; name: string } | null> | null;
};

export const ContactInformationFragmentDoc = gql`
  fragment ContactInformation on ContactInformation {
    city
    comment
    country
    phone
    state
    street
    taxId
    zip
  }
`;
export const LocationFragmentDoc = gql`
  fragment Location on Location {
    id
    name
    description
    email
    openingTime
    closingTime
    locationInformation {
      ...ContactInformation
    }
  }
  ${ContactInformationFragmentDoc}
`;
export const TerminalFragmentDoc = gql`
  fragment Terminal on Terminal {
    id
    name
    description
    status
    location {
      ...Location
    }
    information {
      type
      version
      createdAt
    }
  }
  ${LocationFragmentDoc}
`;
export const ListLocationFragmentDoc = gql`
  fragment ListLocation on Location {
    id
    name
    locationInformation {
      city
      street
    }
  }
`;
export const ListUserFragmentDoc = gql`
  fragment ListUser on User {
    id
    email
    active
    name
    firstName
    lastName
    job
  }
`;
export const OperatorsAutocompleteFragmentDoc = gql`
  fragment OperatorsAutocomplete on User {
    avatar
    email
    firstName
    id
    lastName
    role
  }
`;
export const ListTerminalFragmentDoc = gql`
  fragment ListTerminal on Terminal {
    id
    name
    description
    status
    location {
      locationInformation {
        city
        street
      }
    }
  }
`;
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    avatar
    email
    description
    organizationInformation {
      ...ContactInformation
    }
  }
  ${ContactInformationFragmentDoc}
`;
export const UserFormFragmentDoc = gql`
  fragment UserForm on User {
    id
    avatar
    description
    email
    firstName
    lastName
    active
    role
    job
    locations {
      id
      name
    }
  }
`;
export const GetTerminalLocationsDocument = gql`
  query GetTerminalLocations {
    getLocations {
      id
      name
    }
  }
`;

/**
 * __useGetTerminalLocationsQuery__
 *
 * To run a query within a React component, call `useGetTerminalLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTerminalLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTerminalLocationsQuery,
    GetTerminalLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTerminalLocationsQuery, GetTerminalLocationsQueryVariables>(
    GetTerminalLocationsDocument,
    options
  );
}
export function useGetTerminalLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTerminalLocationsQuery,
    GetTerminalLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTerminalLocationsQuery, GetTerminalLocationsQueryVariables>(
    GetTerminalLocationsDocument,
    options
  );
}
export type GetTerminalLocationsQueryHookResult = ReturnType<typeof useGetTerminalLocationsQuery>;
export type GetTerminalLocationsLazyQueryHookResult = ReturnType<
  typeof useGetTerminalLocationsLazyQuery
>;
export type GetTerminalLocationsQueryResult = Apollo.QueryResult<
  GetTerminalLocationsQuery,
  GetTerminalLocationsQueryVariables
>;
export const OrderTerminalDocument = gql`
  mutation OrderTerminal {
    orderTerminal
  }
`;
export type OrderTerminalMutationFn = Apollo.MutationFunction<
  OrderTerminalMutation,
  OrderTerminalMutationVariables
>;

/**
 * __useOrderTerminalMutation__
 *
 * To run a mutation, you first call `useOrderTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderTerminalMutation, { data, loading, error }] = useOrderTerminalMutation({
 *   variables: {
 *   },
 * });
 */
export function useOrderTerminalMutation(
  baseOptions?: Apollo.MutationHookOptions<OrderTerminalMutation, OrderTerminalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OrderTerminalMutation, OrderTerminalMutationVariables>(
    OrderTerminalDocument,
    options
  );
}
export type OrderTerminalMutationHookResult = ReturnType<typeof useOrderTerminalMutation>;
export type OrderTerminalMutationResult = Apollo.MutationResult<OrderTerminalMutation>;
export type OrderTerminalMutationOptions = Apollo.BaseMutationOptions<
  OrderTerminalMutation,
  OrderTerminalMutationVariables
>;
export const UpdateTerminalDocument = gql`
  mutation UpdateTerminal($input: TerminalInput) {
    updateTerminal(input: $input) {
      ...Terminal
    }
  }
  ${TerminalFragmentDoc}
`;
export type UpdateTerminalMutationFn = Apollo.MutationFunction<
  UpdateTerminalMutation,
  UpdateTerminalMutationVariables
>;

/**
 * __useUpdateTerminalMutation__
 *
 * To run a mutation, you first call `useUpdateTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTerminalMutation, { data, loading, error }] = useUpdateTerminalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTerminalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTerminalMutation, UpdateTerminalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTerminalMutation, UpdateTerminalMutationVariables>(
    UpdateTerminalDocument,
    options
  );
}
export type UpdateTerminalMutationHookResult = ReturnType<typeof useUpdateTerminalMutation>;
export type UpdateTerminalMutationResult = Apollo.MutationResult<UpdateTerminalMutation>;
export type UpdateTerminalMutationOptions = Apollo.BaseMutationOptions<
  UpdateTerminalMutation,
  UpdateTerminalMutationVariables
>;
export const CreateLocationDocument = gql`
  mutation CreateLocation($input: LocationInput) {
    createLocation(input: $input) {
      ...Location
    }
  }
  ${LocationFragmentDoc}
`;
export type CreateLocationMutationFn = Apollo.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(
    CreateLocationDocument,
    options
  );
}
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>;
export const DeleteLocationDocument = gql`
  mutation DeleteLocation($id: ID!) {
    deleteLocation(id: $id)
  }
`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(
    DeleteLocationDocument,
    options
  );
}
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>;
export const GetAutocompleteUsersDocument = gql`
  query GetAutocompleteUsers {
    getUsers {
      ...OperatorsAutocomplete
    }
  }
  ${OperatorsAutocompleteFragmentDoc}
`;

/**
 * __useGetAutocompleteUsersQuery__
 *
 * To run a query within a React component, call `useGetAutocompleteUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutocompleteUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutocompleteUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAutocompleteUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAutocompleteUsersQuery,
    GetAutocompleteUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAutocompleteUsersQuery, GetAutocompleteUsersQueryVariables>(
    GetAutocompleteUsersDocument,
    options
  );
}
export function useGetAutocompleteUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutocompleteUsersQuery,
    GetAutocompleteUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAutocompleteUsersQuery, GetAutocompleteUsersQueryVariables>(
    GetAutocompleteUsersDocument,
    options
  );
}
export type GetAutocompleteUsersQueryHookResult = ReturnType<typeof useGetAutocompleteUsersQuery>;
export type GetAutocompleteUsersLazyQueryHookResult = ReturnType<
  typeof useGetAutocompleteUsersLazyQuery
>;
export type GetAutocompleteUsersQueryResult = Apollo.QueryResult<
  GetAutocompleteUsersQuery,
  GetAutocompleteUsersQueryVariables
>;
export const GetLocationsListDocument = gql`
  query GetLocationsList {
    getLocations {
      ...ListLocation
    }
  }
  ${ListLocationFragmentDoc}
`;

/**
 * __useGetLocationsListQuery__
 *
 * To run a query within a React component, call `useGetLocationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLocationsListQuery, GetLocationsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationsListQuery, GetLocationsListQueryVariables>(
    GetLocationsListDocument,
    options
  );
}
export function useGetLocationsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsListQuery, GetLocationsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationsListQuery, GetLocationsListQueryVariables>(
    GetLocationsListDocument,
    options
  );
}
export type GetLocationsListQueryHookResult = ReturnType<typeof useGetLocationsListQuery>;
export type GetLocationsListLazyQueryHookResult = ReturnType<typeof useGetLocationsListLazyQuery>;
export type GetLocationsListQueryResult = Apollo.QueryResult<
  GetLocationsListQuery,
  GetLocationsListQueryVariables
>;
export const GetTerminalsOverviewDocument = gql`
  query GetTerminalsOverview($id: ID) {
    getTerminalsByLocations(id: $id) {
      id
      description
      status
      name
    }
  }
`;

/**
 * __useGetTerminalsOverviewQuery__
 *
 * To run a query within a React component, call `useGetTerminalsOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalsOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalsOverviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTerminalsOverviewQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTerminalsOverviewQuery,
    GetTerminalsOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTerminalsOverviewQuery, GetTerminalsOverviewQueryVariables>(
    GetTerminalsOverviewDocument,
    options
  );
}
export function useGetTerminalsOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTerminalsOverviewQuery,
    GetTerminalsOverviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTerminalsOverviewQuery, GetTerminalsOverviewQueryVariables>(
    GetTerminalsOverviewDocument,
    options
  );
}
export type GetTerminalsOverviewQueryHookResult = ReturnType<typeof useGetTerminalsOverviewQuery>;
export type GetTerminalsOverviewLazyQueryHookResult = ReturnType<
  typeof useGetTerminalsOverviewLazyQuery
>;
export type GetTerminalsOverviewQueryResult = Apollo.QueryResult<
  GetTerminalsOverviewQuery,
  GetTerminalsOverviewQueryVariables
>;
export const UpdateLocationDocument = gql`
  mutation UpdateLocation($id: ID!, $input: LocationInput) {
    updateLocation(id: $id, input: $input) {
      ...Location
    }
  }
  ${LocationFragmentDoc}
`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(
    UpdateLocationDocument,
    options
  );
}
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      job
      name
      avatar
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CreateUserDocument = gql`
  mutation CreateUser($input: UserInput) {
    createUser(input: $input) {
      id
    }
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const GetAutocompleteLocationsDocument = gql`
  query GetAutocompleteLocations {
    getLocations {
      id
      name
    }
  }
`;

/**
 * __useGetAutocompleteLocationsQuery__
 *
 * To run a query within a React component, call `useGetAutocompleteLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutocompleteLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutocompleteLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAutocompleteLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAutocompleteLocationsQuery,
    GetAutocompleteLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAutocompleteLocationsQuery, GetAutocompleteLocationsQueryVariables>(
    GetAutocompleteLocationsDocument,
    options
  );
}
export function useGetAutocompleteLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAutocompleteLocationsQuery,
    GetAutocompleteLocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAutocompleteLocationsQuery, GetAutocompleteLocationsQueryVariables>(
    GetAutocompleteLocationsDocument,
    options
  );
}
export type GetAutocompleteLocationsQueryHookResult = ReturnType<
  typeof useGetAutocompleteLocationsQuery
>;
export type GetAutocompleteLocationsLazyQueryHookResult = ReturnType<
  typeof useGetAutocompleteLocationsLazyQuery
>;
export type GetAutocompleteLocationsQueryResult = Apollo.QueryResult<
  GetAutocompleteLocationsQuery,
  GetAutocompleteLocationsQueryVariables
>;
export const GetUsersListDocument = gql`
  query GetUsersList {
    getUsers {
      ...ListUser
    }
  }
  ${ListUserFragmentDoc}
`;

/**
 * __useGetUsersListQuery__
 *
 * To run a query within a React component, call `useGetUsersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersListQuery, GetUsersListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersListQuery, GetUsersListQueryVariables>(
    GetUsersListDocument,
    options
  );
}
export function useGetUsersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersListQuery, GetUsersListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersListQuery, GetUsersListQueryVariables>(
    GetUsersListDocument,
    options
  );
}
export type GetUsersListQueryHookResult = ReturnType<typeof useGetUsersListQuery>;
export type GetUsersListLazyQueryHookResult = ReturnType<typeof useGetUsersListLazyQuery>;
export type GetUsersListQueryResult = Apollo.QueryResult<
  GetUsersListQuery,
  GetUsersListQueryVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UserInput) {
    updateUser(input: $input) {
      id
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const GetTerminalDocument = gql`
  query GetTerminal($id: ID) {
    getTerminal(id: $id) {
      id
      description
      name
      error {
        description
      }
      location {
        id
        name
        locationInformation {
          city
          street
        }
      }
    }
  }
`;

/**
 * __useGetTerminalQuery__
 *
 * To run a query within a React component, call `useGetTerminalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTerminalQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTerminalQuery, GetTerminalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTerminalQuery, GetTerminalQueryVariables>(GetTerminalDocument, options);
}
export function useGetTerminalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalQuery, GetTerminalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTerminalQuery, GetTerminalQueryVariables>(
    GetTerminalDocument,
    options
  );
}
export type GetTerminalQueryHookResult = ReturnType<typeof useGetTerminalQuery>;
export type GetTerminalLazyQueryHookResult = ReturnType<typeof useGetTerminalLazyQuery>;
export type GetTerminalQueryResult = Apollo.QueryResult<
  GetTerminalQuery,
  GetTerminalQueryVariables
>;
export const GetTerminalsListDocument = gql`
  query GetTerminalsList {
    getTerminalsByOrganization {
      ...ListTerminal
    }
  }
  ${ListTerminalFragmentDoc}
`;

/**
 * __useGetTerminalsListQuery__
 *
 * To run a query within a React component, call `useGetTerminalsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTerminalsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTerminalsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTerminalsListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTerminalsListQuery, GetTerminalsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTerminalsListQuery, GetTerminalsListQueryVariables>(
    GetTerminalsListDocument,
    options
  );
}
export function useGetTerminalsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTerminalsListQuery, GetTerminalsListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTerminalsListQuery, GetTerminalsListQueryVariables>(
    GetTerminalsListDocument,
    options
  );
}
export type GetTerminalsListQueryHookResult = ReturnType<typeof useGetTerminalsListQuery>;
export type GetTerminalsListLazyQueryHookResult = ReturnType<typeof useGetTerminalsListLazyQuery>;
export type GetTerminalsListQueryResult = Apollo.QueryResult<
  GetTerminalsListQuery,
  GetTerminalsListQueryVariables
>;
export const GetLocationDocument = gql`
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      ...Location
      operators {
        ...OperatorsAutocomplete
      }
    }
  }
  ${LocationFragmentDoc}
  ${OperatorsAutocompleteFragmentDoc}
`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(
  baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
}
export function useGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(
    GetLocationDocument,
    options
  );
}
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<
  GetLocationQuery,
  GetLocationQueryVariables
>;
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: OrganizationInput) {
    createOrganization(input: $input) {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
    CreateOrganizationDocument,
    options
  );
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>;
export const GetOrganizationDocument = gql`
  query getOrganization {
    getOrganization {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options
  );
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    options
  );
}
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($id: ID!, $input: OrganizationInput) {
    updateOrganization(id: $id, input: $input) {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options
  );
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>;
export const GetUserDocument = gql`
  query GetUser($id: ID) {
    getUser(id: $id) {
      ...UserForm
    }
  }
  ${UserFormFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
