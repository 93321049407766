import { ReactNode } from 'react';

import { Box, BoxProps, SxProps } from '@mui/material';
import { Props as BarProps } from 'simplebar-react';

import { RootStyle, SimpleBarStyle } from './Scrollbar.styles';

import 'simplebar-react/dist/simplebar.min.css';

interface ScrollbarProps {
  children: ReactNode;
  sx?: SxProps;
  boxProps?: BoxProps;
  barProps?: BarProps;
}

export const Scrollbar = ({ children, sx, boxProps, barProps }: ScrollbarProps) => {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...boxProps}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...barProps}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  );
};
