import { ReactNode, useMemo } from 'react';

import {
  CssBaseline,
  GlobalStyles,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  ThemeOptions,
  createTheme,
} from '@mui/material';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Shadows } from '@mui/material/styles/shadows';
import { deepmerge } from '@mui/utils';

import { DeepPartial, ThemeDirection, ThemeMode } from './ThemeProvider.types';
import { breakpoints } from './breakpoints';
import { globalStyles } from './globalStyles';
import { componentsOverride } from './overrides';
import { palette } from './palette';
import { customShadows, shadows } from './shadows';
import { shape } from './shape';
import { typography as defaultTypography } from './typography';

import { CustomShadowOptions } from '.';

interface CustomThemes {
  breakpoints: typeof breakpoints;
  customShadows: typeof customShadows;
  palette: typeof palette;
  shadows: typeof shadows;
  shape: typeof shape;
  typography: typeof defaultTypography;
}

type ThemeProviderProps = {
  children: ReactNode;
  themeMode?: ThemeMode;
  themeDirection?: ThemeDirection;
  customThemes?: DeepPartial<CustomThemes>;
};

export const ThemeProvider = ({
  children,
  themeMode = 'light',
  themeDirection = 'ltr',
  customThemes,
}: ThemeProviderProps) => {
  const isDark = themeMode === 'dark';

  const themeOptions: ThemeOptions = useMemo(() => {
    const customShadowsDark: CustomShadowOptions = {
      ...customShadows.dark,
    };
    const customShadowsLight: CustomShadowOptions = {
      ...customShadows.light,
    };

    const shadowsDark: Shadows = {
      ...shadows.dark,
    };
    const shadowsLight: Shadows = {
      ...shadows.light,
    };

    const typography: TypographyOptions = {
      ...defaultTypography,
    };

    return {
      breakpoints,
      customShadows: isDark ? customShadowsDark : customShadowsLight,
      direction: themeDirection,
      palette: isDark ? { ...palette.dark, mode: 'dark' } : { ...palette.light, mode: 'light' },
      shadows: isDark ? shadowsDark : shadowsLight,
      shape,
      typography,
    };
  }, [isDark, themeDirection]);

  const customThemeOptions: DeepPartial<ThemeOptions> = useMemo(() => {
    return {
      ...(isDark &&
        customThemes?.palette?.dark && {
          palette: { ...customThemes.palette.dark, mode: 'dark' },
        }),
      ...(!isDark &&
        customThemes?.palette?.light && {
          palette: { ...customThemes.palette.light, mode: 'light' },
        }),
      ...(customThemes?.shape && { shape: customThemes.shape }),
      ...(customThemes?.typography && { typography: customThemes.typography }),
    };
  }, [customThemes, isDark]);

  const theme = createTheme(deepmerge(themeOptions, customThemeOptions));
  theme.components = componentsOverride(theme);

  const globalStylesComponent = useMemo(
    () => <GlobalStyles styles={() => globalStyles(theme)} />,
    [theme]
  );

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {globalStylesComponent}
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
