import { UserInfo } from '@minimals/core';
import { NavItemType, NavLogo, NavSection, NavigationBar } from '@minimals/navigation-bar';

import { useMeQuery } from '@/graphql';
import { DeviceIcon, EmployeeIcon, LocationIcon, LogoIcon, OrganizationIcon } from '@/icons';
import { ROOTS } from '@/routes';

// TODO i18n
const sidebarItems: NavItemType[] = [
  {
    title: 'Stammdaten',
    path: ROOTS.organization,
    icon: OrganizationIcon,
  },
  {
    title: 'Standorte',
    path: ROOTS.locations,
    icon: LocationIcon,
  },
  {
    title: 'Mitarbeiter',
    path: ROOTS.users,
    icon: EmployeeIcon,
  },
  {
    title: 'Geräte',
    path: ROOTS.devices,
    icon: DeviceIcon,
  },
];

interface NavigationProps {
  isOpen: boolean;
  handleClose(): void;
}

export const Navigation = ({ isOpen, handleClose }: NavigationProps) => {
  const { data } = useMeQuery();
  return (
    <NavigationBar isOpen={isOpen} onClose={handleClose}>
      <NavLogo logo={<LogoIcon />} />
      <UserInfo displayName={data?.me?.name} meta={data?.me?.job} avatar={data?.me?.avatar} />
      <NavSection items={sidebarItems} onItemClick={handleClose} />
    </NavigationBar>
  );
};
