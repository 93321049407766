import { useState } from 'react';

import { Box, Hidden, Table, TableBody, TableContainer, Typography, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { DelayedContent } from '@cubular/core';
import { stringsToList } from '@cubular/utils';
import { ListCell, ListHead, ListRow, LoadingSpinner, SearchInput, useList } from '@minimals/core';

import { GetTerminalsListQuery, ListTerminalFragment } from '@/graphql';
import { HeadLabels, useBreakpoints, useListLabels } from '@/hooks';
import { PATHS, createPath } from '@/routes';
import { listCellWidth } from '@/utils';

import { ListMoreMenu } from '../ListMoreMenu';
import { StatusLabel } from '../StatusLabel';
import { DeviceOrderDialog } from './DeviceOrderDialog';

const HEAD_LABELS: HeadLabels = {
  upLg: [
    { label: 'Name', id: 'name' },
    { label: 'Adresse', id: 'locationInformation.street' },
    { label: 'Status', id: 'status' },
    { label: '', id: 'moremenu' },
  ],
  downLg: [
    { label: 'Name', id: 'name' },
    { label: 'Adresse', id: 'locationInformation.street' },
    { label: '', id: 'moremenu' },
  ],
  downMd: [
    { label: 'Name', id: 'name' },
    { label: '', id: 'moremenu' },
  ],
};

const HeaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

interface DevicesListProps {
  isLoading?: boolean;
  items: GetTerminalsListQuery['getTerminalsByOrganization'];
}

// TODO i18n
export const DevicesList = ({ isLoading, items = [] }: DevicesListProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const { downLgScreen } = useBreakpoints();
  const labels = useListLabels(HEAD_LABELS);

  const { handleFilterByName, handleRequestSort, filterName, order, orderBy, filteredItems } =
    useList(items);

  // const handleOpenDialog = () => setIsDialogOpen(true);
  const handleCloseDialog = () => setIsDialogOpen(false);

  return (
    <>
      <DeviceOrderDialog open={isDialogOpen} handleClose={handleCloseDialog} />

      <HeaderContainer mb={2}>
        <SearchInput
          onChange={(e) => handleFilterByName(e.target.value)}
          placeholder="Geräte suchen..."
          value={filterName}
        />
      </HeaderContainer>

      <TableContainer>
        <Table>
          <ListHead
            order={order}
            orderBy={orderBy}
            rowCount={filteredItems.length}
            headLabel={labels}
            onRequestSort={handleRequestSort}
          />

          <TableBody>
            {filteredItems.map((terminal: ListTerminalFragment) => {
              const editPath = createPath(PATHS.devices.edit, terminal.id);
              return (
                <ListRow id={terminal.id} key={terminal.id} onClick={() => navigate(editPath)}>
                  <ListCell sx={listCellWidth(downLgScreen ? 0.5 : 0.24)}>
                    {terminal?.name}
                  </ListCell>
                  <Hidden mdDown>
                    <ListCell sx={listCellWidth(downLgScreen ? 0.3 : 0.24)}>
                      {stringsToList([
                        terminal?.location?.locationInformation?.street,
                        terminal?.location?.locationInformation?.city,
                      ])}
                    </ListCell>
                  </Hidden>
                  <Hidden lgDown>
                    <ListCell sx={listCellWidth(0.24)}>
                      <StatusLabel isActive={terminal?.status === 'active'} />
                    </ListCell>
                  </Hidden>
                  <ListMoreMenu editPath={editPath} />
                </ListRow>
              );
            })}
          </TableBody>
        </Table>

        {isLoading && <LoadingSpinner sx={{ m: 3 }} />}

        {!isLoading && !filteredItems.length && (
          <DelayedContent LoadingComponent={LoadingSpinner}>
            <Typography sx={{ m: 3 }} variant="body1">
              Keine Geräte gefunden.
            </Typography>
          </DelayedContent>
        )}
      </TableContainer>
    </>
  );
};
