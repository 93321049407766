import { NAVIGATION_WIDTH } from '@minimals/navigation-bar';

export const lgContentWidth = `calc(100vw - ${NAVIGATION_WIDTH}px)`;

export const listCellWidth = (relativeSizeOfCell: number) => ({
  width: {
    xs: `calc(90vw * ${relativeSizeOfCell} )`,
    lg:
      window.innerWidth - NAVIGATION_WIDTH < 1900
        ? `calc(${lgContentWidth} * ${relativeSizeOfCell})`
        : `calc(1900px * ${relativeSizeOfCell})`,
  },
});
