import { FormControl, InputLabel, MenuItem, Select, SelectProps, styled } from '@mui/material';
import { Controller } from 'react-hook-form';

import { RequireAtLeastOne } from '@cubular/utils';

import { FormProps } from './Form.types';

const TextWrapper = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
});

type SelectItem = RequireAtLeastOne<
  {
    id: string;
    [key: string]: any;
  },
  'email' | 'name'
>;

interface FormSelectProps extends FormProps {
  items: SelectItem[];
}

export const FormSelect = ({
  control,
  defaultValue = '',
  items = [],
  label,
  name,
  rules,
  ...selectProps
}: FormSelectProps & SelectProps) => {
  const { fullWidth = true, ...restProps } = selectProps;
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth={fullWidth}>
          <InputLabel>{label}</InputLabel>
          <Select label={label} onChange={onChange} value={value} {...restProps}>
            {items.map((item) => (
              <MenuItem key={item['id']} value={item['id']}>
                <TextWrapper>{item?.name || item.email}</TextWrapper>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
