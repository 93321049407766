import { useTextSnackbar } from '@minimals/use-snackbar';

interface UseMutationSnackbarProps {
  errorMessage?: string;
  successMessage?: string;
}

// TODO i18n
export const useMutationSnackbar = ({
  errorMessage = 'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut.',
  successMessage = 'Ihre Daten wurden erfolgreich aktualisiert.',
}: UseMutationSnackbarProps = {}) => {
  const snackbar = useTextSnackbar();
  return {
    onError: () => snackbar('error', errorMessage),
    onCompleted: () => snackbar('success', successMessage),
  };
};
