import { styled } from '@mui/material';
import { SnackbarProvider as NotiProvider } from 'notistack';

export const SnackbarProvider = styled(NotiProvider)(({ theme }) => ({
  '&.SnackbarItem-contentRoot': {
    backgroundColor: 'white',
    borderRadius: '16px',
    padding: '16px 24px',
  },
  ' &.SnackbarItem-variantSuccess .MuiSvgIcon-root': {
    color: theme.palette.success.dark,
  },
  '&.SnackbarItem-variantError .MuiSvgIcon-root': {
    color: theme.palette.error.main,
  },
}));
