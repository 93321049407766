import { List } from '@mui/material';

import { NavItem } from './NavItem';

export type NavItemType = {
  title: string;
  path: string;
  icon?: string;
  info?: JSX.Element;
  children?: {
    title: string;
    path: string;
  }[];
};

interface NavSectionProps {
  items: NavItemType[];
  onItemClick?(): void;
}

export const NavSection = ({ items, onItemClick }: NavSectionProps) => (
  <List>
    {items.map((item: NavItemType) => (
      <NavItem key={item.title} item={item} onClick={onItemClick} />
    ))}
  </List>
);
